import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import FullPageWrapper from "../components/FullPageWrapper"
import Hero from "../components/Hero"
import "antd/dist/antd.css"
import "./main.scss"

const primaryHeader = "404 Not Found"
const secondaryHeader = (
  <>
    Check out my <Link to="/">homepage</Link>
  </>
)

const NotFound = () => {
  return (
    <div className="NotFound">
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>404 Not Found</title>
        <html lang="en" />
        <meta name="description" content="404 Not Found" />
      </Helmet>
      <FullPageWrapper>
        <Hero primaryHeader={primaryHeader} secondaryHeader={secondaryHeader} />
      </FullPageWrapper>
    </div>
  )
}

export default NotFound
